.privacy_container{
    background-size: cover;
    background-color: #00000078;
    background-blend-mode: color;
    .wrap{
        padding: 45px 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title{
            font-size: 210%;
            font-weight: 500;
            color: white;
            margin-top: 0;
            margin-bottom: 20px;

            
        }
        .text{
            font-size: 100%;
            font-weight: 500;
            color: white;
            text-align: center;
            margin: 0;
        }
        .link{
            margin-top: 20px;
            font-size: 100%;
            font-weight: 500;
        }
    }
}