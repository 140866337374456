@import "../../scss/colors.scss";

.games_container {
  background-size: cover;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  padding-right: 0;
  padding-left: 0;
  position: relative;

  .game_header {
    width: 95%;
    border-radius: 20px;
    background: linear-gradient(360deg, #0b40ba 0%, #5182ff 100%);
    padding: 10px 5%;
    box-sizing: border-box;
    max-height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .custom_button {
      width: 16%;
      height: 100%;
      background-size: contain;
      padding: 10px;
      background-color: transparent;
      border: none;
      background-repeat: no-repeat;
      background-position: center;
    }

    .buny_img {
      width: 17%;
      display: block;
    }

    .level_container {
      position: relative;
      background-color: transparent;
      width: 15%;
      padding: 2px;
      background: #f56e878f;

      border: solid 2px #fff;
      justify-content: space-between;
      display: flex;
      align-items: center;
      flex-direction: column;

      .text {
        &:first-child {
          color: #b00541;
          font-size: 80%;
          font-weight: 500;
        }
        color: getColor("text-color");
        font-size: 80%;
        font-weight: 500;
        font-family: "Bebas_Neue";
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    height: 100vh;
    .list_item {
      height: 78px;
      background-size: cover;
      background-position: center;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // padding: 20px;
      box-sizing: border-box;
      background-repeat: no-repeat;
      width: 55%;
      position: absolute;
      .text{
        font-size: 100%;
        text-align: center;
        color: white;
        font-family: 'Notable', sans-serif;
      }
      .text_wrap{
        display: flex;
        width: 100%;
      }

      .custom_button {
        width: 45%;
        padding: 12px 20px;
        border-radius: 30px;
        border: none;
        border: 1px solid #ff7600;
        background-size: cover;
        color: getColor("text-color");
        font-size: 20px;
        background-position: bottom;
        font-weight: 600;
        font-family: "Kyiv";
      }
    }
    .g1 {
      left: 0;
      top: 16%;
      padding-left: 10px;
      .game_img {
        padding-right: 12%;
        top: -14px;
        position: relative;
        width: 43%;
      }
      .text{
        text-align: left;
      }
    }
    .g2 {
      right: 0;
      top: 32%;
      padding-right: 10px;
     
      .text{
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: end;
        position: relative;
        z-index: 20;
      }
      .game_img {
        top: -50%;
        position: absolute;
        z-index: 10;
        width: 43%;
        left: 15%;
      }
      .game_img_2{
        width: 130%;
        top: -125%;
        position: absolute;
        left: -35%;
      }
    }
    .g3 {
      top: 48%;
      left: 0;
      padding-left: 10px;
      .game_img{
        position: absolute;
        width: 40%;
      top: -5.6vh;
      right: 5%;
      }
      .text{
        display: flex;
        justify-content: start;
        text-align: left;
        position: relative;
        z-index: 20;
      }
    }
    .g4{
      right: 0;
      top: 64%;
      padding-right: 10px;
      .text{
        width: 100%;
        text-align: right;
      }
      .game_img{
        position: absolute;
        width: 56%;
        left: 11%;
        bottom: 1%;
      }

    }

    .g5{
      left: 0;
    top: 80%;
    padding-left: 10px;
      .text{
        width: 100%;
        text-align: center;
        display: flex;
        align-items: end;

      }
      .game_img{
        position: absolute;
        width: 56%;
        right: 11%;
        bottom: 1%;
      }

    }
  }
  .wrap{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: end;
    padding-right: 10px;
    box-sizing: border-box;
    .custom_button{
      top: 51%;
      position: relative;
      background: linear-gradient(180deg, #FE50D8 0%, #BD00FF 100%);
      border-radius: 25px;
      padding: 7% 3%;
      border: none;
      color: #fff;
      font-family: "Notable", sans-serif;
      font-size: 180%;
      font-weight: 500;
    }
  }
}
