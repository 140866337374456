@import "../../scss/colors.scss";

.start_screen{
    width: 100%;
    height: 100vh;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    .title{
        margin: 0;
        color: getColor("text-color");
        font-size: 40px;
        font-weight: 500;
    }
    .loader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 230px;
        flex-direction: column;

        
      }
      
      .rectangles {
        display: flex;
      }
      
      .rect {
        width: 18px;
        height: 40px;
        margin: 0 5px;
        background-color: white;
      }
      .filled{
        background-color:  #C80EF9;
        ;
      }
      
      .loading-text {
        font-size: 24px;
        font-weight: bold;
      }
      
    
      

}