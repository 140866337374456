@font-face {
    font-family: 'Bebas_Neue';
    src: url('../fonts/Bebas_Neue/BebasNeue-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Kyiv';
    src: url('../fonts/Kyiv-Type-Titling/KyivTypeTitling-VarGX.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Lilita_One';
    src: url('../fonts/Lilita_One/LilitaOne-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Notable';
    src: url('../fonts/Notable/Notable-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }




.container{
    width: 100%;
    max-width: 480px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
}

button{
    font-family: inherit;

}

.custom_button{
    font-family: 'Bebas_Neue';
}



body{
    font-family: 'Lilita_One', sans-serif;
}