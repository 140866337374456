@import "../../scss/colors.scss";

.menue_container{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-size: cover;


    .button_wrap{
        display: flex;
        align-items: center;
        width: 100%;
        padding: 10px;
        flex-direction: column;
        height: 50%;
        justify-content: space-evenly;


        .custom_button{
            width: 100%;
            padding: 19px 15px;
            border-radius: 20px;
            font-size: 30px;
            background: getColor("btn_gradient");
            color: getColor("text-color");
            font-weight: 500;
            border: none;
        
        }
    }
}