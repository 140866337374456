@import "../../scss/colors.scss";

.log_in{
    width: 100%;
    height: 100vh;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    .content{
        height: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        .title{
            color: getColor("text-color");
            font-size: 30px;
            font-weight: 500;
            margin: 0;
        }
        .email_input{
            width: 80%;
            padding: 10px;
            height: 65px;
            border-radius: 25px;
            border: none;
            background-color: getColor("input_bg");
            color: getColor("text-color");
            outline: none;
            font-size: 20px;
            font-weight: 600;
        }

        .custom_button{
            padding: 10px 15px;
            min-width: 55%;
            border-radius: 25px;
            border: none;
            background: getColor("btn_gradient");
            color: #FFFFFF;
            font-size: 25px;
            font-weight: 700;
            font-family: 'Kyiv';
            
        }
        .custom_btn_wrap{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .custom_button{
                padding: 10px 15px;
                min-width: 90%;
                border-radius: 25px;
                border: none;
                background: getColor("btn_gradient");
                color: #FFFFFF;
                font-size: 25px;
                font-weight: 700;
            }
        }
    }

  

}